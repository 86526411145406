export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1590261442/general/cflogo.png'
export const about = 'https://d16ux5n37w5c9t.cloudfront.net/galleryTitle.webp'
export const aboutParallax =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto:best/v1612128354/sushiS/aboutParallax.jpg'
export const contactbg =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto:best/v1612128354/sushiS/contactbg.jpg'
export const foodGallery =
  'https://d16ux5n37w5c9t.cloudfront.net/foodGallery.webp'
export const hero = 'https://d16ux5n37w5c9t.cloudfront.net/hero1.webp'
export const heroText =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto:best/v1612129949/sushiS/heroText.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699464398/sushiS/maps.png'
export const mobileAbout =
  'https://d16ux5n37w5c9t.cloudfront.net/mobileGalleryTitle.webp'
export const mobileContactbg =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto:best/v1612128354/sushiS/mobileContactbg.jpg'
export const mobileGallery =
  'https://d16ux5n37w5c9t.cloudfront.net/mobileFoodGallery.webp'
export const mobileHero =
  'https://d16ux5n37w5c9t.cloudfront.net/mobileHero.webp'
export const mobileHeroText =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto:best/v1612128354/sushiS/mobileHeroText.png'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699464480/sushiS/mobileMap.png'
export const mobilePromotion =
  'https://d16ux5n37w5c9t.cloudfront.net/mobilePromo1.webp'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699464398/sushiS/order_pick_up.png'
export const promotions = 'https://d16ux5n37w5c9t.cloudfront.net/promo1.webp'
export const skip =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699464398/sushiS/SKip_the_dishes_real.png'
export const doordash =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699464397/sushiS/door_dash_real.png'
export const uber =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699464398/sushiS/Uber_eats.png'
