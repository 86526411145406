import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promotions, skip, doordash, uber } from 'images'
import OrderPickupButton from './OrderPickupButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="5px" textCenter>
          <CFView column center w="80%" zIndex={90}>
            <CFView pulsate mt="15px">
              <OrderPickupButton />
            </CFView>
            <a href={`https://www.skipthedishes.com/sushi-s-japanese`}>
              <CFView hover mt="10px">
                <CFImage src={skip} w="90%" maxWidth="280px" alt="About" />
              </CFView>
            </a>
            <a
              href={`https://www.ubereats.com/ca/store/sushi-s-japanese-restaurant/68CukN4PVSyBzcJYzKb3xg`}
            >
              <CFView hover mt="10px">
                <CFImage src={uber} w="90%" maxWidth="280px" alt="About" />
              </CFView>
            </a>
            <a
              href={`https://www.doordash.com/en-CA/store/sushi-s-japanese-restaurant-burnaby-24977606/`}
            >
              <CFView hover mt="10px">
                <CFImage src={doordash} w="90%" maxWidth="280px" alt="About" />
              </CFView>
            </a>
          </CFView>
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px">
          <CFView row justifyBetween alignCenter w="80%" zIndex={90}>
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
            <a href={`https://www.skipthedishes.com/sushi-s-japanese`}>
              <CFView hover>
                <CFImage src={skip} maxWidth="220px" alt="About" />
              </CFView>
            </a>
            <a
              href={`https://www.ubereats.com/ca/store/sushi-s-japanese-restaurant/68CukN4PVSyBzcJYzKb3xg`}
            >
              <CFView hover>
                <CFImage src={uber} maxWidth="220px" alt="About" />
              </CFView>
            </a>
            <a
              href={`https://www.doordash.com/en-CA/store/sushi-s-japanese-restaurant-burnaby-24977606/`}
            >
              <CFView hover>
                <CFImage src={doordash} maxWidth="220px" alt="About" />
              </CFView>
            </a>
          </CFView>
          <CFImage mt="-70px" src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
